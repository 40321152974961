import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'logo_vk_color_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="logo_vk_color_28"><path d="M17.22 3.5c5.88 0 7.28 1.4 7.28 7.28v6.44c0 5.88-1.4 7.28-7.28 7.28h-6.44c-5.88 0-7.28-1.4-7.28-7.28v-6.44c0-5.88 1.4-7.28 7.28-7.28h6.44z" fill="#2787F5" /><path d="M8.969 10.063H7.438c-.438 0-.525.205-.525.433 0 .405.519 2.417 2.417 5.077 1.265 1.817 3.048 2.802 4.67 2.802.973 0 1.094-.219 1.094-.596v-1.373c0-.437.092-.525.4-.525.227 0 .617.114 1.525.99 1.038 1.038 1.21 1.504 1.794 1.504h1.53c.438 0 .657-.219.53-.65-.137-.43-.633-1.055-1.29-1.795-.358-.422-.893-.876-1.055-1.103-.227-.292-.162-.422 0-.681l.027-.039c.23-.328 1.852-2.653 2.033-3.482.097-.324 0-.563-.463-.563h-1.531c-.39 0-.569.207-.666.434 0 0-.78 1.898-1.882 3.13-.357.357-.52.47-.714.47-.097 0-.238-.113-.238-.437v-3.034c0-.389-.113-.563-.438-.563H12.25c-.243 0-.39.181-.39.353 0 .369.552.454.609 1.492v2.255c0 .494-.09.584-.284.584-.52 0-1.782-1.907-2.53-4.088-.148-.424-.295-.595-.686-.595z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LogoVkColorProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LogoVkColor: FC<Icon28LogoVkColorProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LogoVkColor as any).mountIcon = mountIcon;

export default Icon28LogoVkColor;
