import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'logo_vk_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="logo_vk_24"><path d="M14.76 3C19.8 3 21 4.2 21 9.24v5.52C21 19.8 19.8 21 14.76 21H9.24C4.2 21 3 19.8 3 14.76V9.24C3 4.2 4.2 3 9.24 3h5.52zM7.688 8.625H6.375c-.375 0-.45.177-.45.371 0 .348.445 2.072 2.072 4.352C9.08 14.906 10.61 15.75 12 15.75c.834 0 .938-.188.938-.51v-1.178c0-.374.079-.45.343-.45.194 0 .528.098 1.307.849.89.89 1.037 1.289 1.537 1.289h1.313c.375 0 .562-.188.454-.557-.119-.37-.543-.904-1.107-1.539-.306-.361-.765-.75-.904-.945-.195-.25-.14-.362 0-.584l.023-.033c.198-.281 1.588-2.274 1.743-2.985.083-.278 0-.482-.397-.482h-1.313a.566.566 0 00-.57.371s-.668 1.627-1.614 2.684c-.305.306-.444.403-.611.403-.084 0-.204-.097-.204-.375v-2.6c0-.334-.097-.483-.376-.483H10.5c-.209 0-.334.155-.334.302 0 .316.473.389.521 1.279v1.933c0 .424-.076.5-.243.5-.445 0-1.527-1.634-2.17-3.504-.125-.363-.251-.51-.586-.51z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LogoVk: FC<Icon24LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LogoVk as any).mountIcon = mountIcon;

export default Icon24LogoVk;
